import React from 'react';
import ReactDOM from 'react-dom/client'; // 변경된 부분
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './components/global/GlobalStyle';
import { RecoilRoot } from 'recoil';
import GlobalModal from './components/global/GlobalModal';
import PushNotificationComponent from './getToken'; // 새로 만든 푸시 알림 컴포넌트 추가

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement); // 변경된 부분

root.render(
  <RecoilRoot>
    <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <GlobalStyle />
        <GlobalModal />
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </RecoilRoot>
);

// If you want to start measuring performance in your app
reportWebVitals();

// Service Worker 등록 코드 추가
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Service Worker 등록 완료:', registration);
    })
    .catch(function (error) {
      console.error('Service Worker 등록 실패:', error);
    });
}
