import {atom} from 'recoil'

export const reportAtom = atom({
  key: 'reportAtom',
  default: [],
})
export const reportParamAtom = atom({
  key: 'reportParamAtom',
  default: {
    searchword: '',
    currentPage: '1',
    pageSize: '10',
    작성자: '',
  },
})

export const firstExportDocument = atom({
  key: 'firstExportDocument',
  default: {
    title: '',
    client: {
      업체명: '업체명',
      거래처코드: '',
    },
    site: {
      현장명: '현장명',
      현장코드: '',
    },
    equip: [],
  },
})
export const exportDocumentBody = atom({
  key: 'exportDocumentBody',
  default: {
    UserInfo: {},
    거래처현황: {},
    거래처세부: {},
    신규사업여부: false,
    계약사항: [{
      사업구분: '',
      매출타입: '',
      장비구분: '',
      기종명: '',
      세부사항: '',
      전압: '',
      방향: '',
      개월: '',
      시작일: '',
      청구구분: '',
      종료일: '',
      납품예정일: '',
      시간: '',
      일시불: '',
      금액: '',
    }],
    운송조건: {
      운송비조건: '',
      운송비청구방법: '',
      운송비: '',
      특기사항: '',
    },
    결제조건: {
      결제방법: '',
      개월: '',
      메일: '',
      담당자: '',
      연락처: '',
      결제처: '',
      수신: '',
      일시불: '',
      계약서: '',
      청구일: '',
      결제일: '',
      특기사항: '',
    },
    신규사업내용: {
      비점오염저감시설: false,
      빗물재이용시설: false,
      오탁수처리시설: false,
      기타: false,
      없음: false,
      특기사항: '',
    },
    계약현황: {
      위약여부: '',
      계약개월: '',
      금액: '',
      사용개월: '',
      운임조건: '',
      운임청구방식: '',
      비고: ''
    },
    축중기체크사항: {
      인디게이터: 0,
      계량판패드1: 0,
      램프4EA: 0,
      케이블2EA: 0,
      계량판패드2: 0,
      충전어댑터: 0,
      장비파손내역: "",
      업무협의사항: ""
    },
    장비리스트: [
      {
        실적NO: "",
        시작일: "",
        매출타입: "",
        입고예정일: "",
        DKNO: "",
        MCNO: "",
        전압: "",
        방향: "",
        기종: "",
        일시불구분: "",
        임대료: "",
        업체명: "",
        거래처코드: "",
        현장명: "",
        현장코드: "",
        부서명: "",
        부서코드: "",
        사원명: "",
        사원코드: "",
        종료일: "",
        초기개월: 0,
        변경개월: 0,
        실적마감수: 0,
        MIF_DKNO: "",
        회사코드: "",
        inoutNO: "",
        출고대기NO: "",
        MIF: ""
      }
    ],
  },
})

export const approvalSuliReq = atom({
  key: 'approvalSuliReq',
  default: {
    구분: '',
    UserInfo: {},
    거래처현황: {},
    거래처세부: {},
    장비리스트: [],
    운송비: {
      입고운송비조건: '',
      입고운송비청구방법: '',
      출고운송비조건: '',
      출고운송비청구방법: '',
      입고운임: '',
      출고운임: '',
      업무협의사항: '',
    },
    결제조건: {
      결제방법: '',
      개월: '',
      메일: '',
      담당자: '',
      연락처: '',
      결제처: '',
      수신: '',
      일시불: '',
      계약서: '',
      청구일: '',
      결제일: '',
      특기사항: '',
    },
  },
})
