import { useRoutes } from 'react-router-dom';
import RootRouter from "./route";
import PushNotificationComponent from './getToken'; // 새로 만든 푸시 알림 컴포넌트 추가

const App = () => {
  return (
    <>
      {useRoutes(RootRouter)}
      <PushNotificationComponent /> {/* 푸시 알림 설정 컴포넌트 렌더링 */}
    </>
  );
};

export default App;
