import { useEffect, useState } from 'react';
import fetchService from './util/fetchService'; // 서버로 데이터를 전송하는 함수
import userAtom from "./recoil/userAtom";
import { useRecoilState } from "recoil";

const PushNotificationComponent = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useRecoilState(userAtom);

  useEffect(() => {
    // Android에서 FCM 토큰을 받을 함수 정의
    window.receiveToken = (fcmToken) => {
      console.log("Android에서 FCM 토큰을 받았습니다: " + fcmToken);
      setToken(fcmToken); // 상태에 FCM 토큰 설정
  
      // 이름, 부서, 사원코드가 모두 유효한지 확인
      const { 한글이름, 부서코드, 사원코드 } = user.auth;
  
      if (!한글이름 || !부서코드 || !사원코드) {
        console.log("유효하지 않은 사용자 정보입니다. 토큰을 저장하지 않습니다.");
        return; // 조건을 만족하지 않으면 서버로 토큰 전송을 하지 않음
      }
  
      // 서버로 토큰 전송
      fetchService('/receipt/storeFcmToken', 'post', {
        이름: 한글이름,
        부서: 부서코드,
        토큰: fcmToken,
        사원코드: 사원코드,
      })
        .then(() => {
          console.log('토큰이 서버에 성공적으로 전송되었습니다.');
        })
        .catch((error) => {
          console.error('토큰을 서버에 전송하는 중 오류가 발생했습니다:', error);
        });
    };
  
    console.log("PushNotificationComponent가 마운트되었습니다. FCM 토큰을 기다리는 중입니다.");
  
    return () => {
      window.receiveToken = null; // 컴포넌트가 언마운트될 때 함수 제거
    };
  }, []); // 빈 배열로 설정하여 컴포넌트가 마운트될 때만 실행
  

  useEffect(() => {
    if (token) {
      console.log("FCM Token:", token);
      // alert는 사용자 경험에 방해가 될 수 있으므로 로그로만 처리하거나 필요에 따라 UI를 변경
      // alert("FCM Token received: " + token);
    }
  }, [token]); // 토큰이 업데이트될 때마다 확인

  return null; // 컴포넌트는 화면에 아무것도 렌더링하지 않음
};

export default PushNotificationComponent;
