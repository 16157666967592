import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useModal} from '../../hooks/useModal'
import fetchService from '../../util/fetchService'
import ReceiptListModal from '../../base-components/modal-components/receipt/ReceiptListModal'
import SearchModal from '../searchModal/index'
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil'
import {newReceiptAtom} from '../../recoil/receipt'
import {updateReceiptState} from '../../util/updateReceiptState'
import userAtom from '../../recoil/userAtom'
import moment from 'moment'
import SingleDate from "../../components/calander/SingleDate";
import {receiptAtom} from "../../recoil/receipt";

const NewRegisModalWrap = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
  width: 100%;

  .modal-top {
    border-radius: 20px 20px 0 0;
    background-color: #fff;
    padding: 15px 20px 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e9e9e9;

    .title {
      font-weight: 700;
      font-size: 16px;
      color: #1c1b1f;
    }
  }

  .modal-body {
    padding: 25px 30px;
    padding-bottom: 70px;
    background-color: #fff;
  }
`

const InputList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.required {
      input {
        background-color: #EFF2FF;
      }
    }

    width: 100%;

    p {
      font-weight: 500;
      font-size: 11px;
      color: #1c1b1f;
      margin-bottom: 4px;
      display: inline-block;

      &.red-point {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 4px;
          height: 4px;
          background-color: #FB0606;
          position: absolute;
          top: 0;
          right: -8px;
          border-radius: 50%;
        }
      }
    }

    input, div.input {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #8885CB;
      background-color: #fff;
      padding: 10px 15px;
      height: 35px;
      border-radius: 10px;
      font-family: var(--font-mont);
      color: #1c1b1f;

      &::placeholder {
        color: #9DA2AE;
      }
    }

    textarea {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #8885CB;
      background-color: #fff;
      padding: 10px 15px;
      height: 120px;
      resize: none;
      border-radius: 10px;
      font-family: var(--font-mont);
      color: #1c1b1f;

      &::placeholder {
        color: #9DA2AE;
      }
    }
  }
`

const ModalBtm = styled.div`
  padding: 17px 30px;
  background-color: #f7f7f7;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  > button {
    cursor: pointer;
    width: calc(50% - 5px);
  }

  .primary-btn {
    height: 34px;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 700;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0129FF;
    border-radius: 10px;
    color: #fff;
  }

  .del-btn {
    padding: 0 15px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1F319D;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    color: #1c1b1f;
    background-color: #fff;
  }
`

const NewRegisModal = ({item, confirm, editable}) => {
  /* ****** 신규접수모달 ****** */
  const {closeModal, openModal} = useModal()
  const pageTitle = '비고'
  const {auth} = useRecoilValue(userAtom)
  const [newReceipt, setNewReceipt] = useRecoilState(newReceiptAtom)
  const resetNewReceipt = useResetRecoilState(newReceiptAtom)
  const [searchModal, setSearchModal] = useState({
    flag: false,
    url: '',
  })
  
  const addNote = (value) => {
    fetchService('/receipt/addNote', 'post', {일련번호: item.no, 비고: value})
      .then(() => {
        closeModal()
        alert('정상등록 되었습니다.')
        window.location.reload()
      })
  }


  const searchFetch = async (searchParam) => {
    return await fetchService(searchModal.url, 'post', searchParam)
  }

  useEffect(() => {

    resetNewReceipt()
    if (item) {
      fetchService('/receipt/detail', 'post', {일련번호: item.no})
        .then(res => {
          console.log(res.data[0])
          setNewReceipt({
            ...res.data[0],
          })
        })
    }
    setNewReceipt({...newReceipt, 접수자: auth.한글이름})
  }, [])


  return (
    <NewRegisModalWrap>
      <div className='modal-top'>
        <h6 className='title'>   {pageTitle} </h6>
      </div>
      <div className='modal-body'>
        <InputList>
          <li>
            <p>비고등록</p>
            <textarea placeholder='비고내용을 입력하세요' value = {newReceipt.비고2} onChange={e => setNewReceipt({
              ...newReceipt,
              비고2: e.target.value,
              비고: newReceipt.비고 ? newReceipt.비고 : ''
            })}
            />
            
          </li>
        </InputList>
        {searchModal.flag && <SearchModal dataAtom={newReceiptAtom} data={searchModal} searchFetch={searchFetch}
                                          setSearchModal={setSearchModal}/>}
      </div>
      {
        !searchModal.flag && <ModalBtm>
          <button className='primary-btn' onClick={() => {
            console.log(newReceipt)
            addNote(newReceipt.비고 + '\n' +  '[' + auth.한글이름 + ']  ' + newReceipt.비고2)
          }}>저장
          </button>
          <button className='del-btn' onClick={() => {

            closeModal()
          }}>취소
          </button>
        </ModalBtm>
        
      }
    </NewRegisModalWrap>   
  )
}


export default NewRegisModal
